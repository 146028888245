import { format } from 'date-fns'
import { Link } from 'gatsby'
import SanityImage from 'gatsby-plugin-sanity-image'
import { getReviewUrl, toPlainText } from '../lib/helpers'
// import urlBuilder from '@sanity/image-url'
import tw, { theme, css } from 'twin.macro'
import Rating from './rating'
import RatingHollow from './rating-hollow'

const breakpoints = [640, 768, 1024]

const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)
const maxMq = breakpoints.map(bp => `@media (max-width: ${bp}px)`)

function ReviewPostPreview(props) {
  return (
    <Link
      to={getReviewUrl(props.slug.current)}
      tw="w-full md:max-w-sm inline-flex"
    >
      <div
        key={props.id}
        className="card group"
        tw="w-full md:max-w-sm md:rounded-xl overflow-hidden inline-block transition ease-in-out border duration-150 md:hover:shadow-sm md:hover:border md:hover:bg-gradient-to-t scale-100 md:hover:scale-105 via-gray-200 transform-gpu"
        css={{
          borderColor: 'transparent',
          [mq[1]]: {
            '&:hover': {
              borderColor: `${theme`borderColor.accent`}`,
            },
          }
        }}
      >
        <div tw="p-0 md:p-6">
          <div tw="flex flex-col space-y-6">
            <div tw="rounded flex flex-row-reverse md:flex-col items-center">
              <div tw="top-0 h-56 w-full">
                {props.mainImage && !props.mainImage.asset._id.includes('png') && (
                  <SanityImage
                    {...props.mainImage}
                    tw="max-h-full object-contain m-auto w-full bg-white rounded"
                  />
                )}
                {props.mainImage && props.mainImage.asset._id.includes('png') && (
                  <SanityImage
                    {...props.mainImage}
                    tw="transform transition duration-150 ease-in-out scale-75 md:scale-90 motion-safe:group-hover:scale-90 md:motion-safe:group-hover:scale-100 -rotate-6 group-hover:rotate-0 max-h-full object-cover"
                    css={{
                      margin: '0 auto !important',
                      '&[data-lqip]': {
                        objectFit: 'cover',
                      },
                    }}
                  />
                )}
              </div>
            </div>
            <div tw="flex flex-row justify-between">
              {props.status && (
                <span
                  tw="inline-block font-sans text-sm py-1 px-2 rounded-md font-medium"
                  css={{
                    backgroundColor: `${props.bgColor.hex}`,
                    color: `${props.textColor.hex}`,
                    width: 'fit-content',
                  }}
                >
                  {props.status}
                </span>
              )}
              {props.rating && (
                <figure tw="flex items-center">
                  <div tw="inline-flex space-x-1">
                    <Rating rating={props.rating} />
                    <RatingHollow rating={props.rating} />
                  </div>
                  <figcaption tw="font-sans sr-only">Rating: {props.rating} out of 5</figcaption>
                </figure>
              )}
            </div>
            <h3 tw="prose text-2xl font-serif">{props.title}</h3>
          </div>
          <p tw="prose text-lg mt-4 text-softer font-serif">{toPlainText(props._rawExcerpt)}</p>
          <small tw="flex-initial mr-2 inline-block mt-4 text-sm text-gray-400 whitespace-nowrap font-sans">
            Last updated at {format(new Date(props._updatedAt), 'do MMM yyyy')}
          </small>
        </div>
      </div>
    </Link>
  )
}

export default ReviewPostPreview

import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes,
        filterOutDocsWithoutSlugs,
        filterOutDocsPublishedInTheFuture,
 } from '../lib/helpers'
import ReviewPostPreviewGrid from '../components/review-post-preview-grid'
import GraphQLErrorList from '../components/graphql-error-list'
import tw, { theme, css } from 'twin.macro'
import Layout from '../containers/layout'
import { Helmet } from 'react-helmet'

export const query = graphql`
  query ReviewListTemplateQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    reviews: allSanityReview(
      sort: { fields: [_updatedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        next {
          id
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
        }
        previous {
          id
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
        }
        node {
          id
          publishedAt
          rating
          textColor {
            hex
          }
          bgColor {
            hex
          }
          status
          mainImage {
            ...ImageWithPreview
            caption
            alt
          }
          title
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          _rawReviews
          _updatedAt
          reviews {
            title
            reviewedAt
          }
          slug {
            current
          }
        }
      }
    }
  }
`

const ReviewListTemplate = (props) => {
  const { data, errors, pageContext } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const reviewNodes = data && data.reviews && mapEdgesToNodes(data.reviews)
  .filter(filterOutDocsWithoutSlugs)
  .filter(filterOutDocsPublishedInTheFuture)

  const site = data.site
  const siteTitle = site.siteMetadata.title
  const domain = site.siteMetadata.siteUrl

  const desc =
    'Personal reviews of things I use often.'

  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={`Personal Reviews · ${siteTitle}`}
      >
        <meta name="description" content={desc} />
        <meta name="image" content={`${domain}${data.file.childImageSharp.fluid.src}`} />

        <meta name="twitter:title" content={`Personal Reviews · ${siteTitle}`} />
        <meta name="twitter:description" content={desc} />
        <meta
          property="twitter:image"
          content={`${domain}${data.file.childImageSharp.fluid.src}`}
        />
        <meta property="twitter:card" content="summary" />

        <meta name="og:title" content={`Personal Reviews · ${siteTitle}`} />
        <meta name="og:description" content={desc} />
        <meta property="og:image" content={`${domain}${data.file.childImageSharp.fluid.src}`} />
        <meta name="og:type" content="website" />
      </Helmet>
      <section tw="w-full">
        <div
          tw="mt-5 pt-5 md:mt-16 md:pt-20 mx-6 md:mx-auto grid grid-cols-1 md:grid-cols-2 font-serif pb-10 md:pb-16 border-b"
          css={{
            width: 'min(1024px, calc(100% - 48px))',
            gridRowGap: 8,
            borderColor: `${theme`borderColor.accent`}`,
          }}
        >
          <h1 tw="text-3xl lg:text-4xl col-span-1 font-semibold ">Personal Reviews</h1>
          <p tw="col-span-1 text-softer prose prose-lg xl:prose-xl">{desc}</p>
        </div>
      </section>
      {reviewNodes && reviewNodes.length > 0 && (
        <ReviewPostPreviewGrid pageContext={pageContext} reviews={reviewNodes} />
      )}
    </Layout>
  )
}

export default ReviewListTemplate
import {Link} from 'gatsby'
import React from 'react'
import ReviewPostPreview from './review-post-preview'
import tw, { theme, css } from 'twin.macro'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

function ReviewPostPreviewGrid(props) {

  return (
    <main tw="mb-24 md:mb-32">
      <section
        css={{
          gridTemplateColumns: 'min(1240px, calc(100% - 48px))',
        }}
      >
        <div
          tw="mt-10 md:mt-24 mb-10 md:mb-20 mx-auto"
          css={{ width: 'min(1240px, calc(100% - 48px))' }}
        >
          <ResponsiveMasonry columnCountBreakPoints={{ 640: 1, 768: 2, 1024: 3 }}>
            <Masonry gutter={'1.5rem'}>
              {props.reviews &&
                props.reviews.map(node => <ReviewPostPreview {...node} key={node.id} />)}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </section>
    </main>
  )
}

ReviewPostPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default ReviewPostPreviewGrid
